import { Fragment } from 'react'
import Link from 'next/link'
import { Popover, Transition } from '@headlessui/react'
import clsx from 'clsx'

import { Button } from './Button'
import { Container } from './Container'
import { Logo } from './Logo'
import { NavLink } from './NavLink'
import React from 'react'
import { LIGHT_BLUE_BUTTON_COLOR } from '../../utils/CONSTANTS'

function MobileNavLink({ href, children, onClick }) {
  return (
      <a href={href} onClick={onClick} className="block w-full p-2">
      {children}
      </a>
  )
}

function MobileNavIcon({ open }) {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible"
      style={{stroke: '#374151'}}
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
    >
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={clsx(
          'origin-center transition',
          open && 'scale-90 opacity-0'
        )}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={clsx(
          'origin-center transition',
          !open && 'scale-90 opacity-0'
        )}
      />
    </svg>
  )
}

function MobileNavigation() {
  return (
    <Popover>
      <Popover.Button
        className="relative z-10 flex h-8 w-8 items-center justify-center outline-none"
        aria-label="Toggle Navigation"
      >
        {({ open }) => <MobileNavIcon open={open} />}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay className="fixed inset-0" style={{backgroundColor: 'rgba(203, 213, 225, 0.7)'}}/>
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            as="div"
            className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg text-gray-900 shadow-xl"
          >
            {({ close }) => (
              <>
                {/* <MobileNavLink href="/#features" onClick={() => close()}>Features</MobileNavLink>
                <MobileNavLink href="/pricing" onClick={() => close()}>Pricing</MobileNavLink>
                <MobileNavLink href="/about-us" onClick={() => close()}>About</MobileNavLink>
                <MobileNavLink href="/contact-us" onClick={() => close()}>Contact</MobileNavLink>
                <hr className="m-2 border-gray-300" /> */}
                <MobileNavLink href="/sign-in" onClick={() => close()}>Sign in</MobileNavLink>
              </>
            )}
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  )
}

export function Header() {
  return (
    <div className= "sticky top-0 z-50 bg-white px-2 sm:px-4 py-2.5 border-gray-200">  
    <header className="py-4">
    <Container>
      <nav className="relative z-50 flex justify-between tracking-tight">
        <div className="flex items-center md:gap-x-12">
          <Link href="/" aria-label="Home">
            <a className="flex items-center h-10 w-36 sm:w-44 lg:w-52">
            <Logo/>
            </a>
          </Link>
        </div>
        <div className="flex items-center gap-x-5 md:gap-x-8">
        {/* <div className="hidden md:block">
            <NavLink href="/#features">Features</NavLink>
          </div>
        <div className="hidden md:block">
            <NavLink href="/pricing">Pricing</NavLink>
          </div>
        <div className="hidden md:block">
            <NavLink href="/contact-us">Contact</NavLink>
          </div>
        <div className="hidden md:block">
            <NavLink href="/about-us">About</NavLink>
          </div> */}
          {/* <div className="hidden md:block text-black text-sm font-medium px-2 py-1 rounded-3xl bg-amber-500">
            <a href="/sign-in">Sign in</a>
          </div> */}
          {/* <a href="/pricing" className='text-white hover:text-gray-100 text-sm font-medium px-2 py-1 rounded-3xl bg-amber-500'>
            <span>
              Sign up
            </span>
          </a> */}
          <div className="-mr-1 md:hidden">
            {/* <MobileNavigation /> */}
          </div>
        </div>
      </nav>
    </Container>
  </header>
  </div>
  )
}
