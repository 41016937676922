import { NextSeo } from 'next-seo';
import React from 'react';
import Page404 from '../components/page-404/page-404.component';
import { APP_URL } from '../constants/app.constants';

export default function ErrorPage() {
  return (
    <>
      <NextSeo
        title="404"
        canonical={`${APP_URL}/404`}
        openGraph={{
          url: `${APP_URL}/404`,
        }}
      />
      <Page404 />
    </>
  );
}
